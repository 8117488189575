@import './vendor/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .stroke-white {
        -webkit-text-stroke: 0.1px #ffffff;
        text-stroke: 0.1px #ffffff;
    }

    .stroke-none {
        -webkit-text-stroke: unset;
        text-stroke: unset;
    }

    .scrollbar-light {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: theme('fontSize.5xs');
            height: theme('fontSize.5xs');
            border-radius: 2rem;
        }

        &::-webkit-scrollbar-thumb {
            background: theme('colors.success.DEFAULT');
            border-radius: 2rem;
        }

        &::-webkit-scrollbar-track {
            background: theme('colors.success.bg');
            border-radius: 2rem;
        }

        & {
            scrollbar-face-color: theme('colors.success.DEFAULT');
            scrollbar-track-color: theme('colors.success.bg');
        }
    }

    .scrollbar-dark {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: theme('fontSize.5xs');
            height: theme('fontSize.5xs');
        }

        &::-webkit-scrollbar-thumb {
            background: theme('colors.success.dark');
            border-radius: 2rem;
        }

        &::-webkit-scrollbar-track {
            background: theme('colors.success.bg');
            border-radius: 2rem;
        }

        & {
            scrollbar-face-color: theme('colors.success.dark');
            scrollbar-track-color: theme('colors.success.bg');
        }
    }

    .scrollbar-hidden {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        & {
            scrollbar-width: none;
        }
    }
}

html {
    @apply text-[10px] 2xs:text-[11px] sm:text-[12px] md:text-[13px] lg:text-base;
}

body {
    @apply font-poppins font-normal;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-poppins font-medium !important;
}

input {
    @apply font-normal;
}

label {
    @apply font-poppins;
}

fieldset {
    @apply border-0;
}

a {
    @apply text-primary dark:text-primary-dark;

    &:hover {
        @apply text-success-hover dark:text-success-hover-dark;
    }
}

a, button {
    &:focus {
        @apply outline-none;
    }
}

svg {
    padding: 0 2px !important;
}

tbody {
    @apply font-roboto;
}

/* Fetchify search box dropdown */
#cc_c2a {
    z-index: 3000 !important;
}

div.ant-modal-wrap > div.ant-modal > div:nth-child(1) {
    padding-bottom: 12rem;
}

div.ant-typography {
    @apply font-roboto;
}

span.ant-typography {
    @apply font-poppins;
}

.rc-virtual-list {
    .rc-virtual-list-scrollbar {
        background: theme('colors.success.bg') !important;
    }

    .rc-virtual-list-scrollbar-thumb {
        background: theme('colors.success.DEFAULT') !important;
    }
}

.ant-table-cell-scrollbar, .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
}

.table-cell-data {
    &.string {
        @apply !text-left;
    }

    &.boolean {
        @apply !text-center;
    }

    &.number {
        @apply !text-right;
    }
}

ul[class*="ReactFlagsSelect"] {
    @apply scrollbar-light;
}

.dark ul[class*="ReactFlagsSelect"] {
    @apply scrollbar-dark;

    & {
        @apply bg-background-dark;

        & li:hover {
            @apply bg-default-hover-dark;
        }

        & div[class*="filterBox"] {
            @apply bg-background-dark;
        }

        & input[placeholder="Search"] {
            @apply bg-background-dark;
        }
    }
}

.ant-modal-wrap {
    overflow-x: hidden;
    overflow-y: auto;
}

.ant-modal-wrap, .ant-table-content, .ant-table-body {
    @apply scrollbar-light;
}

.ant-modal.has-tabs {
    .ant-tabs-tabpane, .ant-tabs-tab, .form-footer {
        background-color: theme('colors.white') !important;
    }
}

.ant-modal.ant-modal {
    padding: 0 2rem 6rem 2rem;
    vertical-align: top;
    top: 4rem;

    .ant-modal-close-x {
        height: 2rem;
        width: 2rem;
        line-height: 2rem;
        margin-top: 0.5rem;
        margin-right: 0.25rem;
    }
}

.ant-modal-confirm {
    & .ant-modal-content {
        border-width: 0.175rem;
        border-radius: 0.75rem;
        border-style: solid;
    }

    &.ant-modal-confirm-confirm .ant-modal-content {
        border: none;
    }

    &.ant-modal-confirm-success .ant-modal-content {
        border-color: theme('colors.success.DEFAULT');
    }

    &.ant-modal-confirm-error .ant-modal-content {
        border-color: theme('colors.danger.DEFAULT');
    }

    &.ant-modal-confirm-warning .ant-modal-content {
        border-color: theme('colors.warning.DEFAULT');
    }

    &.ant-modal-confirm-info .ant-modal-content {
        border-color: theme('colors.info.DEFAULT');
    }
}

.ant-modal-mask {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, .3);
}

.ant-modal-confirm-title {
    margin-top: -1rem;
    overflow: auto;
}

.ant-modal .ant-modal-body {
    /*overflow: hidden;*/
}

.ant-modal.has-tabs {

    * {
        transition: none !important;
    }

    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        border: 0;
        background-color: transparent;
        box-shadow: none;

        .ant-tabs-nav {
            margin-bottom: 0;
            transform: translateY(2px);
        }
    }

    .ant-tabs-tabpane {
        padding: 1rem;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 0;
    }

    .ant-tabs-content.ant-tabs-content-top {
        margin-top: -0.4px;
    }

    .ant-tabs-content-holder {
        border: 2px solid transparent;
        border-bottom: none;
    }

    .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane {
        border-top-right-radius: theme('borderRadius.xl');
    }

    .ant-tabs-tab {
        border-bottom: 2px solid transparent;

        a {
            color: inherit;
        }
    }

    .ant-tabs-nav:before {
        border-bottom: none;
    }

    .ant-tabs-tab-active {
        border: 2px solid transparent;
    }

    .ant-tabs-tab-active, .ant-tabs-content-holder {
        border-bottom: none !important;
    }

    .form-footer {
        border: 2px solid transparent;
        border-top: none;

        &, [class*="grid-"] {
            border-bottom-right-radius: theme('borderRadius.xl');
            border-bottom-left-radius: theme('borderRadius.xl');
        }
    }
}

.ant-modal.has-tabs {
    &[class*="-success"] {
        .ant-tabs-tab-active, .form-footer, .ant-tabs-content-holder {
            border-color: theme('colors.success.DEFAULT');
        }

        .ant-tabs-tab {
            border-bottom-color: theme('colors.success.DEFAULT');
        }
    }

    &[class*="-error"] {
        .ant-tabs-tab-active, .form-footer, .ant-tabs-content-holder {
            border-color: theme('colors.danger.DEFAULT');
        }

        .ant-tabs-tab {
            border-bottom-color: theme('colors.danger.DEFAULT');
        }
    }

    &[class*="-info"] {
        .ant-tabs-tab-active, .form-footer, .ant-tabs-content-holder {
            border-color: theme('colors.info.DEFAULT');
        }

        .ant-tabs-tab {
            border-bottom-color: theme('colors.info.DEFAULT');
        }
    }

    &[class*="-warn"] {
        .ant-tabs-tab-active, .form-footer, .ant-tabs-content-holder {
            border-color: theme('colors.warning.DEFAULT');
        }

        .ant-tabs-tab {
            border-bottom-color: theme('colors.warning.DEFAULT');
        }
    }

    .ant-tabs-tabpane {
        background-color: theme('colors.white');
    }

    .ant-tabs-tab {
        background-color: theme('colors.white') !important;
    }
}

.ant-form-item .ant-form-item-label > label .ant-form-item-tooltip {
    color: theme('colors.info.DEFAULT');
}

.ant-form-item.ant-form-item-has-error {
    margin-bottom: 1.71429rem;

    /*.ant-form-item-explain {
        height: 0;
        min-height: 0;
        opacity: 0;
    }*/
}

.ant-form-item.items-spread-around .ant-col.ant-form-item-control {
    align-items: flex-end;
}

.ant-form-item-label > label > .anticon {
    font-size: 1.25rem;
    margin-bottom: 0.2rem;
}

.ant-input-password-icon {
    color: theme('colors.primary.DEFAULT');
}

.input-align-contents-right {
    input {
        text-align: right !important;
    }
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-input-number-focused,
.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-switch-checked:focus,
.ant-switch:focus {
    box-shadow: none;
}

.ant-checkbox ~ span, .ant-radio ~ span {
    align-self: center;
}

.ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
    @media (max-width: 575px) {
        flex: none;
    }
}

.ant-form .ant-form-item .ant-form-item-control {
    @media (max-width: 575px) {
        flex-grow: 1;
    }
}

.ant-form .ant-form-item.for-switch .ant-form-item-label {
    padding: 0;
}

.table-no-header-border.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.table-no-header-border.ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}


.ant-table-body {
    padding-right: 0.2rem;
}

.ant-table-measure-row {
    div {
        border: none !important;
    }
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: theme('colors.success.DEFAULT');
}

/* this is needed to prevent column heads from sometimes getting a scrollbar */
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 99% !important;
}

th.ant-table-cell {
    .ant-table-column-sorter {
        margin-left: 0.3rem;
    }
}

td.ant-table-cell.ant-table-column-sort {
    background: none;
}

.ant-select-clear {
    margin-top: -0.575rem;
}

.ant-select-item.ant-select-item {
    line-height: 1.715rem;
    border-radius: 0 !important;
}

.ant-select-arrow {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    top: 45%;
}

.or-select-hide-selected-items {
    .ant-select-item-option-selected, .anticon svg {
        height: 0;
        width: 0;
        max-height: 0;
        max-width: 0;
        min-height: 0;
        min-width: 0;
        padding: 0;
    }
}

.ant-picker-suffix {
    margin-top: -0.143rem;
}

.ant-popover-message {
    text-align: center;

    .ant-popover-message-title {
        padding-left: 0;
    }
}